<template>
  <div
    class="modal fade"
    id="kt_modal_sub_add_al"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <Form class="form w-100" @submit="onAddAl" :validation-schema="rules">
          <div class="modal-header">
            <h2 class="fw-bolder">
              {{ $t("Deposit.ADD") }}
            </h2>
            <div
              class="btn btn-icon btn-sm btn-active-icon-primary"
              id="kt_sub_agent_close"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
              </span>
            </div>
          </div>
          <!--begin::Modal body-->
          <div class="modal-body py-10 px-lg-17">
            <!-- Agent Name -->
            <div class="fv-row mb-9">
              <label class="fs-6 fw-bold required mb-2">{{
                $t("Deposit.AddAmount")
              }}</label>
              <Field
                class="form-control form-control-lg form-control-solid"
                name="amount"
                type="number"
              />
              <!-- Error -->
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="amount" />
                </div>
              </div>
            </div>
            <!-- Memo -->
            <div class="fv-row mb-9">
              <label class="fs-6 fw-bold mb-2">{{
                $t("AlHistory.Memo")
              }}</label>
              <Field
                class="form-control form-control-lg form-control-solid"
                name="notes"
                type="text"
              />
              <!-- Error -->
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="notes" />
                </div>
              </div>
            </div>
          </div>
          <!--end::Modal body-->
          <div class="modal-footer flex-center">
            <!--begin::Button-->
            <button
              data-bs-dismiss="modal"
              type="reset"
              id="kt_sub_agent_cancel"
              class="btn btn-light me-3"
            >
              {{ $t("Cancel") }}
            </button>
            <!--end::Button-->
            <!-- Submit -->
            <button
              type="submit"
              ref="submitButton"
              id="kt_sign_in_submit"
              class="btn btn-primary"
            >
              <span class="indicator-label">{{ $t("Deposit.ADD") }}</span>

              <span class="indicator-progress">
                {{ $t("Loading") }}
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <!--end::Button-->
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "subagent-al-transaction",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    subAgentId: { type: String, required: true },
  },
  emits: ["refresh"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const rules = Yup.object().shape({
      amount: Yup.number().integer().min(1000).required(),
      notes: Yup.string(),
    });

    const onAddAl = async (values) => {
      await ApiService.post("/agent/subagents/al/transaction", {
        subAgentId: props.subAgentId,
        action: "ADD",
        amount: Number(values.amount),
        notes: values.notes,
      })
        .then(() => {
          Swal.fire({
            text: t("Deposit.ADD"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("Deposit.AddAlSuccess"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-success",
            },
          });
          document.getElementById("kt_modal_sub_add_al").click();
          emit("refresh");
        })
        .catch((e) => {
          Swal.fire({
            text: e.response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("TryAgain"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    return {
      rules,
      onAddAl,
    };
  },
});
</script>
