
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import StatisticsWidget5 from "@/components/widgets/statsistics/Widget5.vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ApiService from "@/core/services/ApiService";
import AddAlTransaction from "./AddAlTransaction.vue";
import DeductAlTransaction from "./DeductAlTransaction.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

interface IAlDeposit {
  createdAt: string;
  amount: string;
  memo: string;
}

export default defineComponent({
  name: "subagent-al-deposit-history",
  components: {
    StatisticsWidget5,
    Datatable,
    AddAlTransaction,
    DeductAlTransaction,
  },
  setup() {
    // vue variables
    const { t } = useI18n();
    const route = useRoute();
    // page variables
    const tableHeader = ref([
      {
        name: t("Deposit.Date"),
        key: "createdAt",
        text: true,
        sortable: true,
      },
      {
        name: t("Deposit.Type"),
        key: "memo",
        sortable: true,
        customslot: true,
      },
      {
        name: t("Deposit.Amount"),
        key: "amount",
        currency: true,
        sortable: true,
      },
    ]);

    const tableData = ref<Array<IAlDeposit>>([]);
    const subAgent = ref({
      code: "",
      al_balance: 0,
      id: "",
    });
    const totalDeposit = ref<number>(0);

    /**
     * Get Al Deposits
     *
     */
    const getAlDeposits = async (subAgentId: string) => {
      const results = await ApiService.get(
        `/agent/subagents/al/deposits?subAgentId=${subAgentId}`
      )
        .then((res) => res.data)
        .catch(() => []);
      tableData.value.splice(0, tableData.value.length, ...results);
      if (results.length > 0)
        totalDeposit.value = results
          .map((item) => Number(item.amount))
          .reduce((prev, next) => Number(prev) + Number(next));
    };

    /**
     * Sub Agent Info
     *
     */
    const getSubAgentInfo = async (subAgentId: string) => {
      const info = await ApiService.get(`/agent/subagent/${subAgentId}`).then(
        (res) => res.data
      );

      if (info.code) {
        subAgent.value = info;
        setCurrentPageBreadcrumbs(info.code + " " + t("Menu.AlAddHistory"), [
          t("Menu.AgentManage"),
        ]);
      }
    };

    onMounted(() => {
      getSubAgentInfo(String(route.params.subagentid));
      getAlDeposits(String(route.params.subagentid));
      // set breadcrumbs
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(
        t("Agents.Name") + " " + t("Menu.AlAddHistory"),
        [t("Menu.AgentManage")]
      );
    });
    return {
      tableData,
      tableHeader,
      totalDeposit,
      subAgent,
      getAlDeposits,
    };
  },
});
